import { createContext, useState, useEffect } from "react";

const ConfigContext = createContext();

export function ConfigProvider({ children }) {
  const [configuration, setConfiguration] = useState(new Map());
  const [maxQuestions, setMaxQuestions] = useState(10);
  const [maxDifficulty, setMaxDifficulty] = useState(3);

  const addItem = (key, item) => {
    const { __v, _id, ...newItem } = item;
    setConfiguration((prevConfig) => {
      const newConfig = new Map(prevConfig);
      newConfig.set(key, newItem);
      return newConfig;
    });
  };

  const removeItem = (key) => {
    setConfiguration((prevConfig) => {
      const newConfig = new Map(prevConfig);
      newConfig.delete(key);
      return newConfig;
    });
  };

  const resetItems = () => {
    setConfiguration(new Map());
  };

  const setPrevConfiguration = (prevConfig) => {
    if (prevConfig.size !== 0) setConfiguration(prevConfig);
  };

  const fetchData = async () => {
    console.log(typeof configuration);
    if (configuration.size === 0) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}`, {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const res = await response.json();

        // Filtrar los elementos que sean banana, manzana, pera o tomate
        const filteredItems = res.filter(item =>
          ["banana", "manzana", "pera", "tomate"].includes(item.nombre.string.toLowerCase())
        );

        const arrows = ["ArrowDown", "ArrowRight", "ArrowLeft", "ArrowUp"];
        filteredItems.forEach((item, index) => {
          const arrowKey = arrows[index % arrows.length];
          addItem(arrowKey, item);
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  return (
    <ConfigContext.Provider
      value={{
        configuration,
        addItem,
        removeItem,
        resetItems,
        setPrevConfiguration,
        maxQuestions,
        setMaxQuestions,
        maxDifficulty,
        setMaxDifficulty,
        fetchData
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
}

export default ConfigContext;
