import { useState, useContext, useRef, useEffect } from 'react';
import LevelSelector from './LevelSelector';
import SelectMaxDifficulty from './SelectMaxDifficulty';
import SelectMaxQuestions from './SelectMaxQuestions';
import Counter from './Counter';
import ConfigContext from '../contexts/ConfigContext';
import Factory from '../utils/Factory';
import log from '../utils/Logger';
import getElapsedTime from '../utils/ElapsedTime';
import { playAudio, playIncorrectAudio } from '../utils/Audio';

export default function Game() {
  const { configuration, maxQuestions, maxDifficulty, fetchData } = useContext(ConfigContext);
  const [gameState, setGameState] = useState({
    text: '',
    level: null,
    correct: 0,
    incorrect: 0,
    question: 1,
    playing: false,
    startTime: 0,
    isPlayingAudio: false,
  });

  useEffect(() => {
    if (configuration.size === 0) {
      fetchData();
    }
  }, [configuration, fetchData]);

  useEffect(() => {
    if (configuration.size > 0) {
      setGameState(prev => ({
        ...prev,
        level: new Factory(1, configuration, maxDifficulty),
      }));
    }
  }, [configuration, maxDifficulty]);

  const buttonRef = useRef(null);
  let pressedKeys = [];

  // Función para manejar eventos de teclado globales
  const handleKeyDownGlobal = (event) => {
    if (gameState.playing && !gameState.isPlayingAudio) {
      if (!event.repeat) {
        pressedKeys.push(event.code);
        if (pressedKeys.length === gameState.level.difficulty) {
          const success = gameState.level.checkAnswer(pressedKeys, configuration);
          if (success) {
            log(`Tiempo de respuesta: ${getElapsedTime(gameState.startTime)}`);
            log('Correcto');
            setGameState((prev) => ({
              ...prev,
              question: prev.question + 1,
              correct: prev.correct + 1,
            }));
            if (gameState.question > maxQuestions) {
              alert('Sesión finalizada, cantidad máxima de preguntas alcanzada');
              log('Se finalizó la sesión, cantidad máxima de preguntas');
              stop();
            } else {
              updateAudio();
            }
          } else {
            log('Incorrecto');
            setGameState((prev) => ({ ...prev, incorrect: prev.incorrect + 1 }));
            playIncorrectAudio(() => {
              setGameState((prev) => ({ ...prev, isPlayingAudio: true }));
            }, () => {
              setGameState((prev) => ({ ...prev, isPlayingAudio: false }));
            });
          }
        }
      }
    }
  };

  const handleKeyUpGlobal = (event) => {
    if (gameState.playing && gameState.level.getLevel() === 3) {
      pressedKeys = pressedKeys.filter((item) => item !== event.code);
    }
  };

  const updateAudio = () => {
    setGameState((prev) => ({ ...prev, startTime: new Date() }));
    const options = gameState.level.getOption();

    playAudio(options, () => {
      setGameState((prev) => ({ ...prev, isPlayingAudio: true }));
    }, () => {
      setGameState((prev) => ({ ...prev, isPlayingAudio: false }));
    });
  };

  const start = () => {
    if (gameState.level.getOptions().length > 1) {
      log('* * * * * * * * * * * * * * COMENZO LA SESION * * * * * * * * * * * * * * ');
      setGameState((prev) => ({
        ...prev,
        text: '',
        correct: 0,
        incorrect: 0,
        question: 1,
        playing: true,
        startTime: new Date(),
      }));
      updateAudio();
      buttonRef.current.blur();
    } else {
      alert('No hay suficientes elementos para jugar');
    }
  };

  const stop = () => {
    log(' * * * * * * * * * * * * * * TERMINO LA SESION * * * * * * * * * * * * * * ');
    setGameState((prev) => ({
      ...prev,
      text: '',
      playing: false,
      level: new Factory(prev.level.getLevel(), configuration, maxDifficulty),
    }));
    buttonRef.current.blur();
  };

  const handleChange = (value) => {
    setGameState((prev) => ({
      ...prev,
      level: new Factory(value.value, configuration, maxDifficulty),
    }));
  };

  // Agrega y limpia los event listeners globales
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDownGlobal);
    window.addEventListener('keyup', handleKeyUpGlobal);

    return () => {
      window.removeEventListener('keydown', handleKeyDownGlobal);
      window.removeEventListener('keyup', handleKeyUpGlobal);
    };
  }, [gameState]);

  return (
    <div className="flex justify-center py-12">
      <div className="w-full max-w-md bg-white p-4 rounded-lg shadow-md">
        <div className="flex flex-col items-center mb-4">
          <LevelSelector className='w-full' handleChange={handleChange} />
          <button
            type="button"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
            onClick={gameState.playing ? stop : start}
            ref={buttonRef}
            disabled={!gameState.level}
          >
            {gameState.playing ? 'Finalizar' : 'Empezar'}
          </button>
          <h1 className="text-xl mt-4 text-center">{gameState.text}</h1>
        </div>

        <div className="flex flex-col items-center">
          {!gameState.playing && (
            <>
              <SelectMaxQuestions className="w-full" />
              {(gameState.level && (gameState.level.getLevel() === 4 || gameState.level.getLevel() === 5)) && (
                <SelectMaxDifficulty className="w-full" />
              )}
            </>
          )}
          <Counter correct={gameState.correct} incorrect={gameState.incorrect} />
        </div>
      </div>
    </div>
  );
}